// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-regions-tsx": () => import("./../src/pages/regions.tsx" /* webpackChunkName: "component---src-pages-regions-tsx" */),
  "component---src-pages-topics-tsx": () => import("./../src/pages/topics.tsx" /* webpackChunkName: "component---src-pages-topics-tsx" */),
  "component---src-templates-article-tsx": () => import("./../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-articles-tsx": () => import("./../src/templates/Articles.tsx" /* webpackChunkName: "component---src-templates-articles-tsx" */),
  "component---src-templates-author-tsx": () => import("./../src/templates/Author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-region-tsx": () => import("./../src/templates/Region.tsx" /* webpackChunkName: "component---src-templates-region-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../src/templates/Tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

